import React, { useState, useEffect, useMemo, useRef } from "react";
import styles from "./EnqueryForm.module.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import tickMark from "../../../assets/images/tickmark.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MediaAssets from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloseIcon from "@mui/icons-material/Close";
import { Constants } from "constants/Constants";
// import { NewLaunchActions } from "../../../redux-container/investments/new-launch/NewLaunchRedux";
import { NewLaunchActions } from "../../../redux-container/investments/new-launch/NewLaunchRedux";
import { TestimonialsActions } from "../../../redux-container/testimonials/TestimonialsRedux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Paper, Theme, colors, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { toast } from "react-toastify";
import {
  hasOnlyCharacters,
  isEmailValid,
  isMobileNumberValid,
  isOnlyNumberAllowed,
} from "utils/ValidationUtils";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import { Country, State, City } from "country-state-city";
import CircularProgress from "@mui/material/CircularProgress";
import OtpInput from "react-otp-input";
import useClevertap from "app/hooks/useClevertap";
import { EventNameType } from "app/hooks/UseRunCleverTapEvents";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import { debounce } from "@mui/material";
import data from "indian-cities.json";

const useStyles = makeStyles({
  option: {
    color: "black", // Set your desired text color here
    height: "80px",
  },
  inputColor: {
    height: "100px",
    fontSize: "80px",
  },
});
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const mobileFormModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  zindex: 999999,
};

const actionDispatch = (dispatch: any) => {
  return {
    getAllInvestments: () =>
      dispatch(NewLaunchActions.getAllInvestmentsRequest()),
    getTestimonial: () =>
      dispatch(TestimonialsActions.getTestimonialsRequest()),
    getInvestmentDetails: (id: number) =>
      dispatch(InvestmentDetailsActions.getInvestmentDetailsRequest(id)),
    setInvestmentDetailsState: (key: string, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

interface MobileNumberInterface {
  mobilePhoneNumber?: any;
  selectedProject?: any;
  setAyodhya?: any;
  page?: any;
  bookNowBtnClicked?: any;
  setBookNowBtnClicked?: any;
  city?: any;
  openEnquiryPopUpManualy?: boolean;
  setOpenEnquiryPopUpManualy?: any;
  catalogue?: any;
  displayInLeadForm?: any;
}
type SetValueFunction = (value: any) => void;
const EnqueryForm = (props: MobileNumberInterface) => {
  const { mobilePhoneNumber, openEnquiryPopUpManualy } = props;
  const [phoneNumber, setPhoneNumber] = useState<any>("+912269006802");
  const [validatePhone, setValidatePhone] = useState("");
  const [isQrCode, setIsQrCode] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { getInvestmentDetails, setInvestmentDetailsState } = actionDispatch(
    useDispatch()
  );

  const queryParams = new URLSearchParams(location.search);
  const utm_source = queryParams.get("utm_source");
  const utm_campaign = queryParams.get("utm_campaign");
  const utm_medium = queryParams.get("utm_medium");

  useEffect(() => {
    if (location.search.split("=")[1] == "true") {
      setIsQrCode(true);
    }
  }, [location]);

  useEffect(() => {
    if (mobilePhoneNumber) {
      setPhoneNumber(mobilePhoneNumber);
    }
  }, [mobilePhoneNumber]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [openMobileModal, setOpenMobileModal] = React.useState(false);
  const [city, setCity] = useState<any>(null);
  let [timer, setTimer] = useState<any>();
  const [cityErrorMsg, setCityErrorMsg] = useState<any>("");
  const [roleErrorMsg, setRoleErrorMsg] = useState<any>("");
  const [countries, setCountries] = useState<any>([]);
  const [country, setCountry] = useState("India");
  // const [countryCode, setCountryCode] = useState("in");
  const countryCode = useRef("in");
  const [countryDialCode, setCountryDialCode] = useState("91");
  const [isFooterCityDropdownOpen, setIsFooterCityDropdownOpen] =
    useState<boolean>(false);
  const [isModalCityDropdownOpen, setIsModalCityDropdownOpen] =
    useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [filteredCitiesList, setFilteredCitiesList] = useState<String[]>([]);
  const [countryId, setCountryId] = useState<number>(101);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [otp, setOtp] = useState<string>("");
  const noCitiesFound = useRef(false);
  const [pdfDownloadURL, setpdfDownloadURL] = useState("");
  const [enteredCity, setEnteredCity] = useState<any>("");
  const [enteredMobCity, setEnteredMobCity] = useState<any>("");

  const [loader, setLoader] = useState({
    footerEnquirySubmiting: false,
    popUpEnquirySubmiting: false,
  });
  const [disableSelectField, setDisableSelectField] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleMobileModalClose = () => {
    if (mobEnqDetails.city !== enteredMobCity) setEnteredMobCity("");
    // if (!utm_source) {
    setOpenMobileModal(false);
    typeof props?.setOpenEnquiryPopUpManualy === "function" &&
      props?.setOpenEnquiryPopUpManualy(false);
    setOpenMobileModal(false);
    sessionStorage.removeItem("goaModal");
    // }
  };
  const project = useSelector((state: any) => {
    return state?.investmentDetails?.investmentDetails?.projectContent;
  });

  const pageOneData = useSelector(
    (state: any) => state?.pageOneData?.pageOneData
  );

  const [shouldGenerateOtp, setShouldGenerateOtp] = useState<boolean>(
    pageOneData?.page?.isLeadOtpActive
  );

  // useEffect(() => {
  //   let url = project?.opportunityDoc?.brochure?.value?.url;

  //   fetch(url)
  //     .then((response: any) => {
  //       console.log("fetch executed");

  //       setpdfDownloadURL(window.URL.createObjectURL(response?.blob));
  //       return response.blob();
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading PDF:", error);
  //     });
  // }, project);

  useEffect(() => {
    if (location.pathname === "/goa") {
      mobEnqDetails.projectName = "goa";
      desktopEnqDetails.projectName = "goa";
    } else {
      mobEnqDetails.projectName = project?.id;
      desktopEnqDetails.projectName = project?.id;
    }
  }, [project]);

  const [mobEnqDetails, setmobEnqDetails] = useState({
    projectName: location.pathname === "/goa" ? "goa" : project?.id,
    projectId: "",
    crmProjectId: "",
    crmLaunchPhaseId: "",
    fullName: "",
    phone: "",
    email: "",
    city: "",
    role: null,
    country: country,
    countryCode: "",
    countryDialCode: "",
    isQrCode: isQrCode,
    isUpcoming: false,
  });

  const [desktopEnqDetails, setDesktopEnqDetails] = useState({
    projectName: location.pathname === "/goa" ? "goa" : project?.id,
    projectId: "",
    crmProjectId: "",
    crmLaunchPhaseId: "",
    fullName: "",
    phone: "",
    email: "",
    city: "",
    role: null,
    isQrCode: isQrCode,
    isUpcoming: false,
    country: country,
    countryCode: "",
    countryDialCode: "",
  });
  const [format, setFormat] = useState("");

  const { getAllInvestments, getTestimonial } = actionDispatch(useDispatch());

  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [counter, setCounter] = useState(30);
  const [initialTimer, setInitialTimer] = useState(45);
  const [isPopupSubmit, setIsPopupSubmit] = useState(false);
  const [selectedProjectid, setSelectedProjectid] = useState(
    props?.selectedProject ?? desktopEnqDetails?.projectName
  );
  const resendOtp = () => {
    if (initialTimer !== Constants.MAX_RESEND_OTP_TIMER) {
      setInitialTimer((timer: number) => {
        return timer + 15;
      });
      setCounter(initialTimer);
    } else {
      setCounter(Constants.MAX_RESEND_OTP_TIMER);
    }

    handleGenerateOtp(isPopupSubmit ? mobEnqDetails : desktopEnqDetails);
    setIsTimerVisible(true);
  };

  const getInitialList = (countryCode: string) => {
    let citiesOfCountry;

    if (countryCode.toUpperCase() === "IN") {
      citiesOfCountry = data;
    } else {
      citiesOfCountry = City.getCitiesOfCountry(countryCode.toUpperCase());
    }

    // const citiesOfCountry = City.getCitiesOfCountry(countryCode.toUpperCase());
    if (citiesOfCountry) {
      const cityNames = citiesOfCountry.map((city: any) => {
        if (city.name.indexOf(",") !== -1) {
          return city.name.substring(0, city.name.indexOf(","));
        } else return city.name;
      });
      setCities(
        cityNames.filter((item, index) => cityNames.indexOf(item) === index)
      );
      setFilteredCitiesList(
        cityNames.filter((item, index) => cityNames.indexOf(item) === index)
      ); // Initialize filtered list with all cities
    }
  };

  useEffect(() => {
    getAllInvestments();
    getInitialList(countryCode.current);
  }, []);

  useEffect(() => {
    const searchParam = location.search;
    const arr = searchParam.split("&");
    const ans = {};
    if (location.search.split("=")[1] == "true") {
      setIsQrCode(true);
    }
    if (location.pathname === "/ayodhya") {
      setPhoneNumber("02269416836");
    }
  }, [location]);

  useEffect(() => {
    if (props?.bookNowBtnClicked) {
      // console.log("code inside useEffect useless");

      setOpenMobileModal(true);
      props?.setBookNowBtnClicked(false);
    }
  }, [props?.bookNowBtnClicked]);

  useEffect(() => {
    mobEnqDetails.city = city;
  }, [city]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      event.target.closest;
      if (
        isFooterCityDropdownOpen &&
        !event.target.closest(".dropdown-wrapper") &&
        !event.target.closest(".dropdown-parent") &&
        !event.target.closest(".dropdown-icon") &&
        !event.target.closest(".close-dropdown-wrapper")
      ) {
        closeDropdown();
      }
    };

    const handleClickOutsideModal = (event: any) => {
      event.target.closest;
      if (
        isModalCityDropdownOpen &&
        !event.target.closest(".mob-dropdown-wrapper") &&
        !event.target.closest(".mob-dropdown-parent") &&
        !event.target.closest(".mob-dropdown-icon") &&
        !event.target.closest(".mob-close-dropdown-wrapper")
      ) {
        closeDropdown();
      }
    };
    filteredCitiesList.length === 0 &&
      getInitialList(countryCode?.current ?? "in");

    isFooterCityDropdownOpen &&
      document.addEventListener("click", handleClickOutside);
    isModalCityDropdownOpen &&
      document.addEventListener("click", handleClickOutsideModal);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, [isFooterCityDropdownOpen, isModalCityDropdownOpen]);

  const fetchCountry = async () => {
    const response = await fetch(
      `${Constants.PRODUCTION_BASE_URL}hoabl-admin/countries`
    );
    const jsonData = await response.json();

    setCountries(jsonData?.final);
  };

  // useEffect(() => {
  //   fetchCountry();
  // }, []);

  const fetchCities = async () => {
    const response = await fetch(
      `${Constants.PRODUCTION_BASE_URL}hoabl-admin//country/cities/${Number(
        countryId
      )}`
    );
    const jsonData = await response.json();
    // Array.isArray(jsonData?.data?.cities) &&
    //   setCities(
    //     jsonData?.data?.cities.map((item: any) => {
    //       return item.label;
    //     })
    //   );
  };

  // useEffect(() => {
  //   fetchCities();
  // }, [country]);

  useEffect(() => {
    // const modalOpened = sessionStorage.getItem("modalOpened");
    // const res = sessionStorage.getItem("goaModal");

    if (
      props?.bookNowBtnClicked === false ||
      props?.bookNowBtnClicked === undefined
    ) {
      let time = utm_source || utm_campaign || utm_medium ? 7000 : 15000;
      timer = setTimeout(() => {
        setOpenMobileModal(true);
      }, time);
    }
    if (props?.bookNowBtnClicked === true) {
      if (timer) {
        clearTimeout(timer);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [props?.bookNowBtnClicked]);

  let allProjects = useSelector((state: any) => {
    return state?.newLaunch?.investments;
  });

  const filterOnlyDisplayInLeadForm = useMemo(() => {
    let filterData = allProjects?.filter(
      (each: any) =>
        each.status != StatusTypeEnum.Inactive && each.displayInLeadForm
    );
    // let filterData = allProjects?.filter((each: any) => each.status != StatusTypeEnum.Inactive);

    return filterData;
  }, [allProjects]);

  const handleTriggerDataLayer = (response: any) => {
    let windowObj: any = window;
    if (
      response?.data?.crmLaunchPhaseId ===
        "7d9cfba5-1ce5-ee11-a204-000d3a3e4153" &&
      response?.data?.crmProjectId === "297b8fe2-9baf-4e38-8608-5b54fa96d05c"
    ) {
      windowObj.dataLayer.push({
        event: `codename_goa_lead_submit`,
      });
    }

    //condition for ayodhya lead submit
    else if (
      response?.data?.crmLaunchPhaseId ===
        "c7304b60-be03-ef11-9f89-7c1e520e8a80" &&
      response?.data?.crmProjectId === "64812a51-dd14-ee11-9cbe-000d3af2fb98"
    ) {
      windowObj.dataLayer.push({
        event: `ayodha_lead_submit`,
      });
    } else if (
      response?.data?.crmLaunchPhaseId ===
        "f1ccd967-9203-ef11-9f89-7c1e520e8a80" &&
      response?.data?.crmProjectId === "875f7684-afe5-ed11-8848-000d3af2fb98"
    ) {
      windowObj.dataLayer.push({
        event: "soldealibaug_lead_submit",
      });
    }
    //  Dapoli - My First Land -
    else if (
      response?.data?.crmLaunchPhaseId ===
        "d9d12b08-913b-ef11-a316-6045bdcefd4c" &&
      response?.data?.crmProjectId === "3d3d498b-f3dc-ed11-8846-000d3af2bcea"
    ) {
      windowObj.dataLayer.push({
        event: "myfirstland_lead_submit",
      });
      // Codename GOA -
    } else if (
      response?.data?.crmLaunchPhaseId ===
        "a48c722b-7842-ef11-a316-6045bdc5c454" &&
      response?.data?.crmProjectId === "297b8fe2-9baf-4e38-8608-5b54fa96d05c"
    ) {
      windowObj.dataLayer.push({
        event: `codename_goa_lead_submit`,
      });
    }
        // ONE GOA -
        else if (
          response?.data?.crmLaunchPhaseId ===
            "0dc4e2d0-b458-ef11-bfe2-000d3a3e297b" &&
          response?.data?.crmProjectId === "297b8fe2-9baf-4e38-8608-5b54fa96d05c"
        ) {
          windowObj.dataLayer.push({
            event: `codename_goa_lead_submit`,
          });
        }
  };

  const handleDeskOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setDesktopEnqDetails((preVal) => {
      return { ...preVal, [name]: value };
    });
  };

  const handleSelectedDeskCity = (e: any) => {
    const selectedCity = e.target.innerText;
    if (isFooterCityDropdownOpen) {
      setIsFooterCityDropdownOpen(!isFooterCityDropdownOpen);
    }
    if (selectedCity === "No city found" || selectedCity === "") {
      setEnteredCity("");
    } else {
      setEnteredCity(selectedCity);
      setDesktopEnqDetails((preVal) => {
        return { ...preVal, city: selectedCity };
      });
    }
    getInitialList(countryCode.current);
  };

  const handleSelectedMobCity = (e: any) => {
    const selectedCity = e.target.innerText;
    if (isModalCityDropdownOpen) {
      setIsModalCityDropdownOpen(!isModalCityDropdownOpen);
    }
    if (selectedCity === "No city found" || selectedCity === "") {
      setEnteredMobCity("");
    } else {
      setmobEnqDetails((preVal) => {
        return { ...preVal, city: selectedCity };
      });
      setEnteredMobCity(selectedCity);
    }
    getInitialList(countryCode.current);
  };

  const toggleDropdown = () => {
    if (isFooterCityDropdownOpen) {
      desktopEnqDetails.city === "" && setEnteredCity("");
    }
    setIsFooterCityDropdownOpen(!isFooterCityDropdownOpen);
  };

  const openFooterDropdown = () => {
    desktopEnqDetails.city === "" && setEnteredCity("");
    setIsFooterCityDropdownOpen(!isFooterCityDropdownOpen);
  };
  const openMobDropdown = () => {
    mobEnqDetails.city === "" && setEnteredMobCity("");
    setIsModalCityDropdownOpen(!isModalCityDropdownOpen);
  };

  const toggleMobDropdown = () => {
    if (isModalCityDropdownOpen) {
      mobEnqDetails.city === "" && setEnteredMobCity("");
    }

    setIsModalCityDropdownOpen(!isModalCityDropdownOpen);
  };

  const calculateDropdownPosition = () => {
    if (dropdownRef.current) {
      const dropdownHeight = dropdownRef.current?.clientHeight;
      const parentRect =
        dropdownRef?.current?.parentElement?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Calculate whether to show below or above
      if (parentRect) {
        const spaceBelow = viewportHeight - parentRect?.bottom;
        const spaceAbove = parentRect?.top;
        if (spaceBelow > spaceAbove) {
          return {
            top: "100%",
            bottom: "auto",
            maxHeight: `${spaceBelow > 480 ? "480px" : spaceBelow}`,
          }; // Display below
        } else {
          return {
            top: "auto",
            bottom: "48px",
            maxHeight: `${spaceAbove > 480 ? "480px" : spaceBelow}`,
          }; // Display above
        }
      }
    }

    return { top: "100%", bottom: "auto", maxHeight: "30vh" };
  };

  // to close the dropdown when clicked outside modal
  const closeDropdown = () => {
    if (isFooterCityDropdownOpen) {
      setIsFooterCityDropdownOpen(!isFooterCityDropdownOpen);
      setEnteredCity("");
    }

    if (isModalCityDropdownOpen) {
      setIsModalCityDropdownOpen(!isModalCityDropdownOpen);
      setEnteredMobCity("");
    }
  };

  //custom functon to search the filter
  const filterCity = (value: string, setValue: SetValueFunction) => {
    const regex = new RegExp(`^${value}[aA-zZ]`, "i");
    const exactRegex = new RegExp(`^${value}$`, "i");
    const updatedCitiesList = cities.filter((city) => {
      return regex.test(city) || exactRegex.test(city);
    });
    setFilteredCitiesList(updatedCitiesList);
  };

  const debouncedHandleCityOnChange = debounce(
    (value: string, setValue: SetValueFunction) => {
      //  const returnedValue = filterCity(value);
      //  setFilteredCitiesList(returnedValue);
      filterCity(value, setValue);
    },
    1500
  );

  const handleDeskCityOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!isFooterCityDropdownOpen) {
      setIsFooterCityDropdownOpen(!isFooterCityDropdownOpen);
    }
    // debouncedHandleCityOnChange(value);
    debouncedHandleCityOnChange(value, setDesktopEnqDetails);
    setEnteredCity(value);
  };

  const handleProjectOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setDesktopEnqDetails((preVal) => {
      return { ...preVal, [name]: value };
    });
    setSelectedProjectid(value);
    getInvestmentDetails(value);
  };

  const handPhoneOnChange = (phone: any, country: any) => {
    setShouldGenerateOtp(true);
    setErrorMsg("");
    setOtp("");
    setFormat(country.format);

    if (
      country?.countryCode === desktopEnqDetails?.country.toLocaleLowerCase()
    ) {
      setDesktopEnqDetails((preVal) => {
        return {
          ...preVal,
          phone: phone,
          country: countryCode.current,
          countryCode: `+${country.dialCode}`,
          countryDialCode: country.dialCode,
        };
      });
    } else {
      setDesktopEnqDetails((preVal) => {
        return {
          ...preVal,
          phone: phone,
          country: country.countryCode.toUpperCase(),
          countryCode: `+${country.dialCode}`,
          countryDialCode: country.dialCode,
          city: "",
        };
      });
    }

    // setCountryCode(country.countryCode.toUpperCase());
    countryCode.current = country.countryCode.toUpperCase();
    getInitialList(country.countryCode);
  };

  const handmobileNoOnChange = (phone: any, country: any) => {
    setShouldGenerateOtp(true);
    setErrorMsg("");
    setOtp("");
    setFormat(country.format);
    if (country?.countryCode === mobEnqDetails?.country) {
      setmobEnqDetails((preVal) => {
        return {
          ...preVal,
          phone: phone,
          country: countryCode.current,
          countryCode: `+${country.dialCode}`,
          countryDialCode: country.dialCode,
        };
      });
    } else {
      setmobEnqDetails((preVal) => {
        return {
          ...preVal,
          phone: phone,
          country: country.countryCode.toUpperCase(),
          countryCode: `+${country.dialCode}`,
          countryDialCode: country.dialCode,
          city: "",
        };
      });
    }
    // setCountryCode(country.countryCode.toUpperCase());
    countryCode.current = country.countryCode.toUpperCase();
    getInitialList(country.countryCode);
  };

  const handleMobileOnchange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setmobEnqDetails((preVal) => {
      return { ...preVal, [name]: value };
    });
  };

  const handleCityOnchange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    // if (hasOnlyCharacters(value)) {
    if (!isModalCityDropdownOpen) {
      setIsModalCityDropdownOpen(!isModalCityDropdownOpen);
    }
    debouncedHandleCityOnChange(value, setmobEnqDetails);
    setEnteredMobCity(value);
  };

  const handleProjectMobileOnchange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setmobEnqDetails((preVal) => {
      return { ...preVal, [name]: value };
    });
    getInvestmentDetails(value);
    setSelectedProjectid(value);
  };

  const projectDetails = useSelector((state: any) => {
    return state?.preLoginPageManagement?.investmentDetails
      ?.pageManagementsOrNewInvestments;
  });

  const footerphoneValidation = (desktopEnqDetails: any) => {
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    let requiredLength = format.split(".").length - 1;

    if (
      desktopEnqDetails.countryDialCode == "91" &&
      desktopEnqDetails.phone.length < 12
    ) {
      // setErrorMsg("Please Enter 10 digit phone-number");
      toast.error("Please Enter 10 digit phone-number", {
        className: "toast_error_message",
        autoClose: 1500,
      });
      return true;
    } else if (
      desktopEnqDetails.countryDialCode == "91" &&
      !desktopEnqDetails.phone
    ) {
      // setErrorMsg("Phone Number is empty !");
      toast.error("Phone Number is empty !", {
        className: "toast_error_message",
        autoClose: 1500,
      });
      return true;
    } else if (
      desktopEnqDetails.countryDialCode == "91" &&
      !isMobileNumberValid(desktopEnqDetails.phone)
    ) {
      toast.error("Phone number not valid !", {
        className: "toast_error_message",
        autoClose: 1500,
      });

      return true;
    } else if (
      desktopEnqDetails.countryDialCode !== "91" &&
      regex.test(`+${desktopEnqDetails.phone}`)
    ) {
      toast.error("Please enter valid international phone number.", {
        className: "toast_error_message",
        autoClose: 1500,
      });

      return true;
    } else return false;
  };

  const submitQuery = async (e: any) => {
    e.preventDefault();

    if (
      // !desktopEnqDetails.projectName ||
      !desktopEnqDetails.fullName ||
      !desktopEnqDetails.phone ||
      !desktopEnqDetails.email
      // || !hasOnlyCharacters(desktopEnqDetails.city)
    ) {
      toast.error("Enter all the fields correctly", {
        className: "toast_error_message",
      });
      return;
    }

    if (
      desktopEnqDetails.countryDialCode == "91" &&
      desktopEnqDetails.phone.length < 12
    ) {
      toast.error("Please Enter 10 digit phone-number", {
        autoClose: 1500,
      });
      return;
    }

    setLoader({
      ...loader,
      footerEnquirySubmiting: true,
      popUpEnquirySubmiting: true,
    });

    let projName = desktopEnqDetails.projectName;
    let payload = {};
    let id = "";
    if (projName != "ayodhya" && projName != "goa") {
      const res = allProjects?.filter((data: any) => {
        return desktopEnqDetails.projectName == data.id;
      });

      // desktopEnqDetails.projectName = res[0]?.launchName;
      desktopEnqDetails.projectId = String(res[0]?.id);
      // desktopEnqDetails.crmProjectId = String(res[0]?.crmProjectId);
      // desktopEnqDetails.crmLaunchPhaseId = String(res[0]?.crmLaunchPhaseId);
      desktopEnqDetails.crmProjectId = project?.crmProject?.crmId;
      desktopEnqDetails.crmLaunchPhaseId = project?.crmLaunchPhase?.crmId;
      id = String(res[0]?.id);
      payload = { ...desktopEnqDetails, projectName: res[0]?.launchName };
    }

    let goaData = {
      fullName: desktopEnqDetails?.fullName,
      phone: desktopEnqDetails?.phone,
      email: desktopEnqDetails?.email,
      projectName: "Vasco At Goa",
      projectId: "projectidgoa2023",
      crmProjectId: "crmprojgoa2023",
      crmLaunchPhaseId: "crmlaunchphaseidgoa2023",
      isUpcoming: true,
    };
    let ayodhyaData = {
      fullName: desktopEnqDetails?.fullName,
      phone: desktopEnqDetails?.phone,
      email: desktopEnqDetails?.email,
      projectName: "Ayodhya",
      projectId: "64812a51-dd14-ee11-9cbe-000d3af2fb98",
      crmProjectId: "crmprojayodhya2023",
      crmLaunchPhaseId: "d13888c2-df14-ee11-9cbe-000d3af2fb98",
      isUpcoming: true,
      city: desktopEnqDetails?.city,
      role: desktopEnqDetails?.role,
      isQrCode: isQrCode,
    };
    if (props.setAyodhya || projName === "ayodhya") {
      payload = { ...ayodhyaData };
    }
    if (projName === "goa") {
      payload = { ...goaData };
    }

    let phoneNumberWithoutCountryCode = desktopEnqDetails?.phone.slice(
      desktopEnqDetails?.countryDialCode?.length
    );
    payload = {
      ...payload,
      phone: phoneNumberWithoutCountryCode,
    };

    if (!desktopEnqDetails?.crmLaunchPhaseId) {
      payload = { ...payload, crmLaunchPhaseId: "na" }; // if crm launchphase id is not there assign it to na
    }
    const response = await fetch(
      `${Constants.PRODUCTION_BASE_URL}hoabl-admin/create-leads${location.search}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: pageOneData?.page?.isLeadOtpActive
          ? JSON.stringify({
              ...payload,
              phone: phoneNumberWithoutCountryCode,
              otp,
            })
          : JSON.stringify({
              ...payload,
              phone: phoneNumberWithoutCountryCode,
            }),
      }
    );
    setLoading(true);

    let ans = await response?.json();
    setLoader({
      ...loader,
      footerEnquirySubmiting: false,
      popUpEnquirySubmiting: false,
    });

    if (ans?.code === 200) {
      handleTriggerDataLayer(ans);
      setLoading(false);
      if (utm_source) {
        sessionStorage.setItem("url", window.location.href);
        navigate(RouteConfigurations.thankYou, { state: project });
        let url = project?.opportunityDoc?.brochure?.value?.url;
        redirectToPdf(url, desktopEnqDetails);
        setInvestmentDetailsState("investmentDetails", null);
        // window.location.href = "/thank-you";
      } else {
        // redirectToPDPPage(project);
        sessionStorage.setItem("url", window.location.href);
        navigate(RouteConfigurations.thankYou);
        let url = project?.opportunityDoc?.brochure?.value?.url;
        redirectToPdf(url, desktopEnqDetails);
        setInvestmentDetailsState("investmentDetails", null);
        // window.location.href = "/thank-you";
        // toast.error(ans.message || "Something went wrong!", {
        //   autoClose: 1500,
        // });
      }
    }
    if (projName != "ayodhya" && projName != "goa") {
      setLoading(false);
      desktopEnqDetails.projectName = id;
    }
    if (response.status == 400) {
      setLoading(false);
      toast.error(ans.message || "something went wrong", {
        className: "toast_error_message",
      });
    }
  };

  useEffect(() => {
    if (counter === 0) {
      setIsTimerVisible(false);
    }
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const extractFilenameFromUrl = (url: any) => {
    const decodedUrl = decodeURIComponent(url);

    const urlParts = decodedUrl.split("/");
    return urlParts[urlParts.length - 1];
  };

  const { clevertapLoginUser, clevertapPushEvent } = useClevertap();

  const redirectToPdf = async (url: any, data: any) => {
    let userdata = {
      Name: data.fullName,
      Email: data.email,
      Phone: `+${data?.phone}`,
      Project: project?.launchName,
      ProjectId: data.projectId,
      City: data?.city,
      "MSG-whatsapp": true,
    };
    clevertapLoginUser(userdata);
    clevertapPushEvent(EventNameType.LAED_CAPTURE, userdata);
    window.open(url);

    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    link.download = extractFilenameFromUrl(url);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const redirectToPDPPage = (data: any) => {
    navigate(`/investment-details/${data.launchName}`, {
      state: {
        projectId: data.id,
        area: data?.areaStartingFrom,
        cost: data.priceStartingFrom,
        title: data.launchName,
        location: data.address.city,
        description: data.shortDescription,
        isDetailed: data.isSoldOut,
        isExplore: data.isSoldOut,
        isSold: data.isSoldOut,
        dataAsObject: data,
      },
    });
    window.location.reload();
  };

  const phoneValidation = (mobEnqDetails: any) => {
    let requiredLength = format.split(".").length - 1;
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    // if (mobEnqDetails?.phone?.length != requiredLength) {
    //   setErrorMsg("Phone Number length is invalid.");
    //   return true;
    // }
    if (
      mobEnqDetails.countryDialCode == "91" &&
      mobEnqDetails.phone.length < 12
    ) {
      setErrorMsg("Please Enter 10 digit phone-number");
      return true;
    } else if (mobEnqDetails.countryDialCode == "91" && !mobEnqDetails.phone) {
      setErrorMsg("Phone Number is empty !");
      return true;
    } else if (
      mobEnqDetails.countryDialCode == "91" &&
      !isMobileNumberValid(mobEnqDetails.phone)
    ) {
      setErrorMsg("Phone number not valid !");
      return true;
    } else if (
      mobEnqDetails.countryDialCode !== "91" &&
      regex.test(`+${mobEnqDetails.phone}`)
    ) {
      setErrorMsg("Please enter valid international phone number.");
      return true;
    } else return false;
  };
  //function for handle modal form submission
  const haneldeMobileSubmit = async (e: any) => {
    e.preventDefault();
    if (!mobEnqDetails.fullName) return;
    if (!mobEnqDetails.city) return;
    if (phoneValidation(mobEnqDetails)) return;

    if (!isEmailValid(mobEnqDetails.email)) {
      setErrorMsg("Please enter valid email !");
      return;
    }
    if (!hasOnlyCharacters(mobEnqDetails.city)) {
      setErrorMsg("Please enter valid city !");
      return;
    }

    let projName = mobEnqDetails.projectName;

    let payload = {};
    let id;
    let res;

    if (projName != "ayodhya" && projName != "goa") {
      res = allProjects?.filter((data: any) => {
        return mobEnqDetails.projectName == data.id;
      });

      // mobEnqDetails.projectName = res[0]?.launchName;
      mobEnqDetails.projectId = String(res[0]?.id);
      // mobEnqDetails.crmProjectId = String(res[0]?.crmProjectId);
      // mobEnqDetails.crmLaunchPhaseId = String(res[0]?.crmLaunchPhaseId);
      mobEnqDetails.crmProjectId = project?.crmProject?.crmId;
      mobEnqDetails.crmLaunchPhaseId = project?.crmLaunchPhase?.crmId;
      id = String(res[0]?.id);
      payload = { ...mobEnqDetails, projectName: res[0]?.launchName };
    }

    if (!res?.length) {
      setErrorMsg("Please Select Project !");
      return;
    }
    setErrorMsg(null);

    setLoader({ ...loader, popUpEnquirySubmiting: true });

    let goaData = {
      fullName: mobEnqDetails?.fullName,
      phone: mobEnqDetails?.phone,
      email: mobEnqDetails?.email,
      projectName: "Vasco At Goa",
      projectId: "projectidgoa2023",
      crmProjectId: "crmprojgoa2023",
      crmLaunchPhaseId: "crmlaunchphaseidgoa2023",
      city: mobEnqDetails?.city,
      isUpcoming: true,
    };

    let ayodhyaData = {
      fullName: mobEnqDetails?.fullName,
      phone: mobEnqDetails?.phone,
      email: mobEnqDetails?.email,
      projectName: "Ayodhya",
      projectId: "64812a51-dd14-ee11-9cbe-000d3af2fb98",
      crmProjectId: "crmprojayodhya2023",
      crmLaunchPhaseId: "d13888c2-df14-ee11-9cbe-000d3af2fb98",
      city: mobEnqDetails?.city,
      role: mobEnqDetails?.role,
      country: country,
      isQrCode,
      isUpcoming: true,
    };

    if (props.setAyodhya || projName === "ayodhya") {
      payload = { ...ayodhyaData };
    }
    if (projName === "goa") {
      payload = { ...goaData };
    }

    if (location.pathname === "/ayodhya" && mobEnqDetails?.role === null) {
      setRoleErrorMsg("Please Select Role");
    } else {
      setRoleErrorMsg("");
    }
    if (location.pathname === "/ayodhya" && mobEnqDetails?.city === null) {
      setCityErrorMsg("Please Select City");
    } else {
      setCityErrorMsg("");
    }

    let phoneNumberWithoutCountryCode = mobEnqDetails?.phone?.slice(
      mobEnqDetails?.countryDialCode?.length
    );
    payload = {
      ...payload,
      phone: phoneNumberWithoutCountryCode,
    };
    if (!mobEnqDetails?.crmLaunchPhaseId) {
      payload = { ...payload, crmLaunchPhaseId: "na" }; // if crm launchphase id is not there assign it to na
    }
    if (location.pathname === "/ayodhya" && mobEnqDetails?.city != null) {
      const response = await fetch(
        `${Constants.PRODUCTION_BASE_URL}hoabl-admin/create-leads${location.search}`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },
          body: pageOneData?.page?.isLeadOtpActive
            ? JSON.stringify({
                ...payload,
                phone: phoneNumberWithoutCountryCode,
                otp,
              })
            : JSON.stringify({
                ...payload,
                phone: phoneNumberWithoutCountryCode,
              }),
        }
      );

      let ans = await response?.json();

      if (ans?.code == 200) {
        handleTriggerDataLayer(ans);
        sessionStorage.setItem("url", window.location.href);
        navigate(RouteConfigurations.thankYou);
      }
    }
    if (location.pathname !== "/ayodhya") {
      let ans;
      try {
        const response = await fetch(
          `${Constants.PRODUCTION_BASE_URL}hoabl-admin/create-leads${location.search}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
            },
            body: pageOneData?.page?.isLeadOtpActive
              ? JSON.stringify({ ...payload, otp })
              : JSON.stringify(payload),
          }
        );

        ans = await response?.json();

        if (response?.status === 400) {
          setErrorMsg(ans?.message || "Something went wrong.");
        }
      } catch (error: any) {
        setLoader({ ...loader, popUpEnquirySubmiting: false });
      }

      setLoader({ ...loader, popUpEnquirySubmiting: false });

      if (ans?.code == 200) {
        handleTriggerDataLayer(ans);
        if (utm_source) {
          sessionStorage.setItem("url", window.location.href);
          navigate(RouteConfigurations.thankYou, { state: project });
          let url = project?.opportunityDoc?.brochure?.value?.url;
          redirectToPdf(url, mobEnqDetails);
          setInvestmentDetailsState("investmentDetails", null);
        } else {
          // redirectToPDPPage(project);
          sessionStorage.setItem("url", window.location.href);
          navigate(RouteConfigurations.thankYou);
          let url = project?.opportunityDoc?.brochure?.value?.url;
          redirectToPdf(url, mobEnqDetails);
          setInvestmentDetailsState("investmentDetails", null);
        }
      }
    }
    if (projName != "ayodhya" && projName != "goa") {
      mobEnqDetails.projectName = id;
    }
  };

  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const [openVerifyOtpModal, setOpenVerifyOtpModal] = useState(false);

  const handleGenerateOtp = async (mobEnqDetails: any, footerEnquiry?: any) => {
    if (!isEnquiryForm) {
      setLoader({ ...loader, popUpEnquirySubmiting: true });
    } else {
      setLoader({ ...loader, footerEnquirySubmiting: true });
    }
    if (
      footerEnquiry
        ? footerphoneValidation(mobEnqDetails)
        : phoneValidation(mobEnqDetails)
    ) {
      footerEnquiry
        ? setLoader({ ...loader, footerEnquirySubmiting: false })
        : setLoader({ ...loader, popUpEnquirySubmiting: false });
      return;
    } else {
      const response: any = await fetch(
        `${Constants.PRODUCTION_BASE_URL}hoabl-customer/generate_otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "version": 'v2' //for prod deployment
          },
          body: JSON.stringify({
            phoneNumber: mobEnqDetails?.phone
              ?.split("")
              ?.slice(String(mobEnqDetails?.countryDialCode).length)
              ?.join(""),
            countryCode: `+${mobEnqDetails?.countryDialCode}`,
            country: countryCode.current,
            source: "lead_verification",
          }),
        }
      );

      if (response?.status === 200) {
        setOtp("");
        setShouldGenerateOtp(false);
        setOpenMobileModal(false);
        setOpenVerifyOtpModal(true);
        setCounter(initialTimer);
        setLoader({
          ...loader,
          footerEnquirySubmiting: false,
          popUpEnquirySubmiting: false,
        });
      } else if (response?.status === 500) {
        setLoader({
          ...loader,
          footerEnquirySubmiting: false,
          popUpEnquirySubmiting: false,
        });
        toast.error(response.message || "Something went wrong!", {
          autoClose: 1500,
        });
      }
    }
  };
  const handleSetOtp = (e: any) => {
    if (isOnlyNumberAllowed(e.target.value) || e.target.value === "") {
      setOtp(e.target.value);
    }
  };

  const [isEnquiryForm, setIsEnquiryForm] = useState(false);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  //to check whether current project is matching with the list or not
  const shouldDisableSelectField = useMemo(() => {
    const selectedProject = filterOnlyDisplayInLeadForm.filter(
      (el: any) => props.selectedProject === el?.id
    );

    // Compute whether to disable the select field
    const result = !!props.displayInLeadForm && selectedProject.length > 0;
    selectedProject.length > 0 && setSelectedProjectid(selectedProject[0]?.id);
    return result;
  }, [props.displayInLeadForm, props.selectedProject]);

  useEffect(() => {
    setDisableSelectField(shouldDisableSelectField);
  }, [shouldDisableSelectField]);

  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.enqueryCon}>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className={styles.modalStyle}>
                <div className={styles.cotentBox}>
                  <img src={MediaAssets.website_logo} alt="tick mark" />
                  <div className={styles.heading}>Enquiry Submitted</div>
                  <div className={styles.desc}>
                    THANK YOU FOR REGISTERING YOUR INTEREST WITH US. SOMEONE
                    WILL GET IN TOUCH WITH YOU SHORTLY
                  </div>
                  <button
                    className={styles.okayBtn}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Okay
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
          {/* FOOTER FORM */}
          <form
            onSubmit={(e) => {
              if (pageOneData?.page?.isLeadOtpActive) {
                e?.preventDefault();
                setIsPopupSubmit(false);
                handleGenerateOtp(desktopEnqDetails, true);
              } else {
                submitQuery(e);
              }
            }}
            className={styles.enqFormStyle}
          >
            {location?.pathname === "/ayodhya" ||
            location?.pathname === "/goa" ? (
              <>
                <select
                  className={styles.dropDownStyle}
                  name="projectName"
                  onChange={handleDeskOnChange}
                >
                  <option
                    value={location?.pathname === "/goa" ? "goa" : "ayodhya"}
                  >
                    {location?.pathname === "/goa" ? "Vasco At Goa" : "Ayodhya"}
                  </option>
                </select>
              </>
            ) : (
              <>
                <select
                  className={styles.dropDownStyle}
                  name="projectName"
                  // onChange={desktopOnChangehandler}
                  // onChange={handleDeskOnChange}
                  onChange={handleProjectOnChange}
                  required
                  // disabled={
                  //   props?.displayInLeadForm &&
                  //   getSelectedProject(props?.selectedProject)
                  // }
                  disabled={disableSelectField}
                >
                  <option value="">Select Project Name</option>
                  {/* <option value="goa">Vasco At Goa</option> */}
                  {/* <option value="ayodhya">Ayodhya</option> */}

                  {filterOnlyDisplayInLeadForm?.map(
                    (data: any, index: number) => {
                      return (
                        <React.Fragment key={index * 1.222}>
                          <option
                            selected={
                              selectedProjectid === data?.id
                              // props?.selectedProject === data?.id ||
                              // desktopEnqDetails?.projectName === data?.id
                            }
                            value={data?.id}
                          >
                            {data?.launchName}
                          </option>
                        </React.Fragment>
                      );
                    }
                  )}
                </select>
              </>
            )}
            <div className={styles.border_input_style}>
              <input
                type="text"
                className={styles.inputStyle}
                placeholder="Full Name"
                name="fullName"
                onChange={handleDeskOnChange}
                required
                autoComplete="off"
              />
            </div>
            <div className={styles.border_input_style}>
              <PhoneInput
                country={countryCode.current.toLowerCase()}
                // inputClass={styles.inputPhone}
                inputClass={styles.phoneInputFont}
                inputProps={{
                  required: true,
                }}
                searchClass={styles.phoneInputFont}
                searchStyle={{ width: "80%", textAlign: "left" }}
                dropdownClass={styles.footerEnquiry}
                containerClass={styles.phoneInputFont}
                inputStyle={{
                  border: "none",
                  backgroundColor: "black",
                  color: "#ffff",
                  textAlign: "start",
                  width: "200px",
                }}
                // dropdownClass="enquery"
                buttonStyle={{
                  backgroundColor: "black",
                  border: "none",
                }}
                dropdownStyle={{
                  margin: "-243px 0 10px -1px",
                  backgroundColor: "black",
                  color: "#ffff",
                }}
                enableSearch={true}
                value={desktopEnqDetails.phone}
                disableSearchIcon={true}
                onChange={(phone, country) => handPhoneOnChange(phone, country)}
                // disableCountryGuess={desktopEnqDetails?.phone?.length>4 ||desktopEnqDetails?.phone?.length===0  ?false:true}
                disableCountryGuess={true}
                countryCodeEditable={false}
              />
              {/* <input
                type="number"
                className={styles.inputStyle}
                placeholder="Phonerfrffrd"
                name="phone"
                onChange={handleDeskOnChange}
                required
              /> */}
            </div>
            <div
              // className={styles.border_input_style} //removed for selectfield
              className={`${styles.border_input_style} dropdown-parent`} //removed for selectfield
              style={{
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => !isFooterCityDropdownOpen && openFooterDropdown()}
            >
              {isFooterCityDropdownOpen && (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Select City"
                    className={styles.inputStyle}
                    style={{ width: "100%" }}
                    name="search-city"
                    autoComplete="off"
                    onChange={handleDeskCityOnChange}
                    value={enteredCity}
                    // required
                  />
                  {enteredCity.length > 1 && (
                    <div
                      className="close-dropdown-wrapper"
                      style={{
                        position: "absolute",
                        bottom: "40%",
                        right: "30px",
                        width: "24px",
                        height: "auto",
                        // padding:' 12px 16px',
                        transform: "translateY(50%)",
                        zIndex: 12,
                      }}
                    >
                      <svg
                        enable-background="new 0 0 64 64"
                        viewBox="0 0 64 64"
                        id="close"
                        onClick={() => {
                          if (desktopEnqDetails.city === enteredCity) {
                            setDesktopEnqDetails((preVal) => {
                              return { ...preVal, city: "" };
                            });
                          }
                          setEnteredCity("");
                          getInitialList(countryCode.current);
                        }}
                      >
                        <g transform="translate(378 278)">
                          <path
                            fill="#fff"
                            d="M-345.9-222.1c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9S-322-259.2-322-246c0 13.1-10.7 23.9-23.9 23.9zm0-45.3c-11.7 0-21.3 9.6-21.3 21.3 0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3c0-11.7-9.6-21.3-21.3-21.3z"
                          ></path>
                          <path
                            fill="#fff"
                            d="m-356.3-233.8-1.9-1.9 22.6-22.6 1.9 1.9-22.6 22.6"
                          ></path>
                          <path
                            fill="#fff"
                            d="m-335.6-233.8-22.6-22.6 1.9-1.9 22.6 22.6-1.9 1.9"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  )}
                </div>
              )}
              {/*    <input
                type="text"
                placeholder="City"
                className={styles.inputStyle}
                name="city"
                autoComplete="off"
                onChange={handleDeskCityOnChange}
                required
              /> */}
              <input
                type="text"
                placeholder="Select City"
                className={styles.inputStyle}
                style={{
                  width: "100%",
                  display: `${!isFooterCityDropdownOpen ? "block" : "none"}`,
                }}
                name="city"
                autoComplete="off"
                // onChange={handleDeskCityOnChange}
                value={desktopEnqDetails.city}
                // required
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "40%",
                  right: "0px",
                  width: "24px",
                  height: "auto",
                  // padding:' 12px 16px',
                  transform: "translateY(50%)",
                  zIndex: 12,
                }}
              >
                <svg
                  className="dropdown-icon"
                  style={{
                    width: "100%",
                    height: "auto",
                    transform: `${
                      isFooterCityDropdownOpen
                        ? "rotate(-180deg)"
                        : "rotate(0deg)"
                    }`,
                    transition: "0.2s",
                    cursor: "pointer",
                  }}
                  onClick={toggleDropdown}
                  height="48"
                  viewBox="0 0 48 48"
                  width="48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"
                    fill="#fff"
                  />
                  <path d="M0-.75h48v48h-48z" fill="none" />
                </svg>
              </div>
              {isFooterCityDropdownOpen && (
                <div
                  className="dropdown-wrapper"
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    borderRadius: "4px",
                    width: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    backgroundColor: "white",
                    height: "auto",
                    maxHeight: "480px",
                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                    // padding:' 12px 16px',

                    padding: "14px 0px",
                    zIndex: 1,
                  }}
                >
                  {filteredCitiesList.length === 0 ? (
                    <>
                      <p
                        className={styles.dropdownListText}
                        style={{
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={handleSelectedDeskCity}
                      >
                        No city found
                      </p>
                    </>
                  ) : (
                    filteredCitiesList?.map((city, index) => {
                      return (
                        <>
                          <p
                            className={styles.dropdownListText}
                            style={{
                              backgroundColor: `${
                                desktopEnqDetails.city === city ? "#4287e8" : ""
                              }`,
                              color: `${
                                desktopEnqDetails.city === city ? "#fff" : ""
                              }`,
                            }}
                            key={`city_desk_${index}`}
                            onClick={handleSelectedDeskCity}
                          >
                            {city}
                          </p>
                        </>
                      );
                    })
                  )}
                </div>
              )}
              {/* <select
                    className={styles.dropDownStyle}
                    // style={{marginTop:'1.5rem'}}
                    name="city"
                    onChange={handleDeskCityOnChange}
                    required
                    // value={enquiryModalValue.projectName}
                  >
                    <option value="">Select City</option>
                    {cities?.map(
                      (data: any, index: string) => {
                        return (
                          <>
                            <option
                              selected={
                                props?.city == data ||
                                mobEnqDetails?.city == data
                              }
                              value={data?.name}
                              key={`city_desk_${index}`}
                            >
                              {data}
                            </option>
                          </>
                        );
                      }
                    )}
                  </select> */}
            </div>
            <div className={styles.border_input_style}>
              <input
                type="email"
                placeholder="Email ID"
                className={styles.inputStyle}
                name="email"
                autoComplete="off"
                onChange={handleDeskOnChange}
                required
              />
            </div>

            <button type="submit" className={styles.submitBtn}>
              {loader?.footerEnquirySubmiting ? (
                <CircularProgress size={24} />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
      <div className={styles.mobileView}>
        <Modal
          open={
            !openVerifyOtpModal &&
            (openMobileModal || props.openEnquiryPopUpManualy === true)
          }
          // open={true}
          onClose={handleMobileModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // onClick={closeDropdown}
        >
          <Box
            sx={mobileFormModalStyle}
            className={
              location?.pathname === "/ayodhya"
                ? styles.mobileFormModalStyleeAyodhya
                : styles.mobileFormModalStylee
            }
          >
            <div
              className={styles.closeBtn}
              onClick={() => {
                // if (!utm_source) {
                setOpenMobileModal(false);
                typeof props?.setOpenEnquiryPopUpManualy === "function" &&
                  props?.setOpenEnquiryPopUpManualy(false);
                // sessionStorage.setItem("modalOpened", "true");
                sessionStorage.removeItem("goaModal");
                // }
              }}
            >
              <CloseIcon
                style={{ color: "white" }}
                className={styles.closeIcon}
              />
            </div>
            <div className={styles.innerContent}>
              <div className={styles.forBorderLeft}></div>
              <div className={styles.forBorderRight}></div>
              <div className={styles.navigateBack}>
                <div className={styles.backText}>Enquire Now</div>
              </div>
              {/* POPUP FORM */}
              <form
                className={styles.mobileform}
                onSubmit={(e) => {
                  setIsEnquiryForm(true);
                  if (pageOneData?.page?.isLeadOtpActive) {
                    e?.preventDefault();
                    setIsPopupSubmit(true);
                    handleGenerateOtp(mobEnqDetails, false);
                  } else {
                    haneldeMobileSubmit(e);
                  }
                }}
              >
                <div className={styles.mobile_input_style}>
                  {location?.pathname === "/ayodhya" ||
                  location?.pathname === "/goa" ? (
                    <>
                      <select
                        className={styles.mobileFormDropDown}
                        name="projectName"
                        onChange={handleMobileOnchange}
                        // value={enquiryModalValue.projectName}
                      >
                        <option
                          value={
                            location?.pathname === "/goa" ? "goa" : "ayodhya"
                          }
                        >
                          {location?.pathname === "/goa"
                            ? "Vasco At Goa"
                            : "Ayodhya"}
                        </option>
                      </select>
                    </>
                  ) : (
                    <>
                      <select
                        className={styles.mobileFormDropDown}
                        name="projectName"
                        // onChange={desktopOnChangehandler}
                        // onChange={handleMobileOnchange}
                        onChange={handleProjectMobileOnchange}
                        required
                        // value={enquiryModalValue.projectName}
                        // disabled={
                        //   props?.displayInLeadForm &&
                        //   getSelectedProject(props?.selectedProject)
                        // }
                        disabled={disableSelectField}
                      >
                        <option value="">Select Project Name</option>
                        {/* <option value="goa">Vasco At Goa</option> */}
                        {/* <option value="ayodhya">Ayodhya</option> */}

                        {filterOnlyDisplayInLeadForm?.map(
                          (data: any, index: number) => {
                            return (
                              <React.Fragment key={index * 1.232}>
                                <option
                                  selected={
                                    selectedProjectid === data?.id
                                    // props?.selectedProject == data?.id ||
                                    // mobEnqDetails?.projectName == data?.id
                                  }
                                  value={data?.id}
                                >
                                  {data?.launchName}
                                </option>
                              </React.Fragment>
                            );
                          }
                        )}
                      </select>
                    </>
                  )}
                </div>
                <div className={styles.mobile_input_style}>
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    className={styles.mobileFormInput}
                    required
                    onChange={handleMobileOnchange}
                    value={mobEnqDetails.fullName}
                  />
                </div>
                <div className={styles.mobile_input_style}>
                  <PhoneInput
                    country={countryCode.current.toLowerCase()}
                    containerClass={styles.phoneInput}
                    inputStyle={{
                      border: "none",
                      backgroundColor: "transparent",
                      textAlign: "start",
                    }}
                    inputClass={styles.phoneInput}
                    dropdownClass={styles.enquery}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    value={mobEnqDetails.phone}
                    enableSearch={true}
                    disableSearchIcon={true}
                    searchStyle={{ width: "80%", textAlign: "left" }}
                    onChange={(phone, country) =>
                      handmobileNoOnChange(phone, country)
                    }
                    disableCountryGuess={true}
                    countryCodeEditable={false}
                  />
                </div>
                <div
                  className={`${styles.mobile_input_style} mob-dropdown-parent`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  // onClick={toggleMobDropdown}
                  onClick={() => !isModalCityDropdownOpen && openMobDropdown()}
                >
                  {/* <input
                    type="text"
                    placeholder="City"
                    name="city"
                    className={styles.mobileFormInput}
                    required
                    onChange={handleCityOnchange}
                    value={c}
                  /> */}
                  {/* <select
                    className={styles.mobileFormDropDown}
                    style={{ marginTop: "1.5rem" }}
                    name="city"
                    onChange={handleCityOnchange}
                    required
                    // value={enquiryModalValue.projectName}
                  >
                    <option value="">Select City</option>
                    {cities?.map((data: any, index: string) => {
                      return (
                        <>
                          <option
                            selected={
                              props?.city == data || mobEnqDetails?.city == data
                            }
                            value={data?.name}
                            key={`city_${index}`}
                          >
                            {data}
                          </option>
                        </>
                      );
                    })}
                  </select> */}
                  {isModalCityDropdownOpen && (
                    <div
                      className="mob-close-dropdown-wrapper"
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Select City"
                        className={styles.mobileFormInput}
                        style={{ width: "100%" }}
                        name="city"
                        autoComplete="off"
                        onChange={handleCityOnchange}
                        value={enteredMobCity}
                        // required
                      />
                      {enteredMobCity.length > 1 && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "12px",
                            right: "30px",
                            width: "24px",
                            height: "auto",
                            // padding:' 12px 16px',
                            transform: "translateY(50%)",
                            zIndex: 1,
                          }}
                        >
                          <svg
                            enable-background="new 0 0 64 64"
                            viewBox="0 0 64 64"
                            id="close"
                            onClick={() => {
                              if (mobEnqDetails.city === enteredMobCity) {
                                setmobEnqDetails((preVal) => {
                                  return { ...preVal, city: "" };
                                });
                              }
                              setEnteredMobCity("");
                              getInitialList(countryCode.current);
                            }}
                          >
                            <g transform="translate(378 278)">
                              <path
                                fill="#000000"
                                d="M-345.9-222.1c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9S-322-259.2-322-246c0 13.1-10.7 23.9-23.9 23.9zm0-45.3c-11.7 0-21.3 9.6-21.3 21.3 0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3c0-11.7-9.6-21.3-21.3-21.3z"
                              ></path>
                              <path
                                fill="#000000"
                                d="m-356.3-233.8-1.9-1.9 22.6-22.6 1.9 1.9-22.6 22.6"
                              ></path>
                              <path
                                fill="#000000"
                                d="m-335.6-233.8-22.6-22.6 1.9-1.9 22.6 22.6-1.9 1.9"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Select City"
                    className={styles.mobileFormInput}
                    style={{
                      width: "100%",
                      display: `${!isModalCityDropdownOpen ? "block" : "none"}`,
                    }}
                    name="city"
                    autoComplete="off"
                    // onChange={handleCityOnchange}
                    value={mobEnqDetails.city}
                    // required
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      right: "0px",
                      width: "24px",
                      height: "auto",
                      // padding:' 12px 16px',
                      transform: "translateY(50%)",
                      zIndex: 1,
                    }}
                  >
                    <svg
                      className="close-dropdown-icon"
                      style={{
                        width: "100%",
                        height: "auto",
                        transform: `${
                          isModalCityDropdownOpen
                            ? "rotate(-180deg)"
                            : "rotate(0deg)"
                        }`,
                        transition: "0.2s",
                        cursor: "pointer",
                      }}
                      onClick={toggleMobDropdown}
                      height="48"
                      viewBox="0 0 48 48"
                      width="48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"
                        fill="#000"
                      />
                      <path d="M0-.75h48v48h-48z" fill="none" />
                    </svg>
                  </div>
                  {isModalCityDropdownOpen && (
                    <div
                      className="mob-dropdown-wrapper"
                      style={{
                        position: "absolute",
                        // bottom: "100%",
                        borderRadius: "4px",
                        width: "100%",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        backgroundColor: "white",
                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                        // padding:' 12px 16px',

                        height: "auto",
                        padding: "14px 0px",
                        zIndex: 1,
                        top: calculateDropdownPosition().top,
                        bottom: calculateDropdownPosition().bottom,
                        maxHeight: calculateDropdownPosition().maxHeight,
                      }}
                      ref={dropdownRef}
                    >
                      {filteredCitiesList.length === 0 ? (
                        <>
                          <p
                            className={styles.dropdownListText}
                            style={{
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={handleSelectedMobCity}
                          >
                            No city found
                          </p>
                        </>
                      ) : (
                        filteredCitiesList?.map((city, index) => {
                          return (
                            <>
                              <p
                                className={styles.dropdownListText}
                                style={{
                                  backgroundColor: `${
                                    mobEnqDetails.city === city ? "#4287e8" : ""
                                  }`,
                                  color: `${
                                    mobEnqDetails.city === city ? "#fff" : ""
                                  }`,
                                }}
                                key={`city_desk_${index}`}
                                onClick={handleSelectedMobCity}
                              >
                                {city}
                              </p>
                            </>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
                <div className={styles.mobile_input_style}>
                  <input
                    type="text"
                    placeholder="Email ID"
                    name="email"
                    className={styles.mobileFormInput}
                    required
                    onChange={handleMobileOnchange}
                    value={mobEnqDetails.email}
                  />
                </div>

                {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
                {location?.pathname === "/ayodhya" && (
                  <>
                    <div className={styles.CountryAndCity}>
                      <Autocomplete
                        disablePortal
                        className={styles.mobileFormDropDown}
                        id="combo-box-demo"
                        options={countries ? countries : []}
                        classes={{
                          option: classes.option,
                        }}
                        defaultValue={country}
                        onChange={(event, newValue: any) => {
                          setCountry(newValue?.label);
                          setCountryId(newValue?.id);
                        }}
                        sx={{ width: 250, margin: "10px 0px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              background: "white",
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "25px",
                            }}
                            {...props}
                          />
                        )}
                      />
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        className={styles.cityStyle}
                        options={cities ? cities : []}
                        classes={{
                          option: classes.option,
                        }}
                        onChange={(event, newValue: any) => {
                          setCity(newValue?.label);
                        }}
                        sx={{
                          width: 250,
                          margin: "10px 0px 0px 10px",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="City" />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              background: "white",
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "25px",
                            }}
                            {...props}
                          />
                        )}
                        renderOption={(props, item) => (
                          <li {...props} key={item.key}>
                            {item.label}
                          </li>
                        )}
                      />
                    </div>
                    <div className={styles.cityErrorMsg}>{cityErrorMsg}</div>
                  </>
                )}

                <button type="submit" className={styles.mobileSubmitBtn}>
                  {loader?.popUpEnquirySubmiting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </Box>
        </Modal>

        {/* Verify OTP modal */}
        <Modal
          open={openVerifyOtpModal}
          onClose={() => setOpenVerifyOtpModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={mobileFormModalStyle}
            className={
              location?.pathname === "/ayodhya"
                ? styles.mobileFormModalStyleeAyodhya
                : styles.mobileFormModalStylee
            }
          >
            <div
              className={styles.closeBtn}
              onClick={() => {
                setOpenVerifyOtpModal(false);
              }}
            >
              <CloseIcon
                style={{ color: "white" }}
                className={styles.closeIcon}
              />
            </div>
            <div className={styles.innerContent}>
              <div className={styles.forBorderLeft}></div>
              <div className={styles.forBorderRight}></div>
              <div className={styles.navigateBack}>
                <div className={styles.backText}>Verify OTP</div>
              </div>
              <form
                className={styles.mobileform}
                onSubmit={(e) => {
                  if (isEnquiryForm) {
                    haneldeMobileSubmit(e);
                  } else {
                    submitQuery(e);
                  }
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" component={"p"}>
                    Enter OTP code sent to{" "}
                    <span
                      onClick={() => {
                        setOpenVerifyOtpModal(false);
                        setErrorMsg("");
                        if (isEnquiryForm) {
                          setOpenMobileModal(true);
                        } else {
                          setOpenVerifyOtpModal(false);
                        }
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      {`+${mobEnqDetails?.phone || desktopEnqDetails?.phone}`}
                    </span>
                  </Typography>
                </Box>
                {pageOneData?.page?.isLeadOtpActive && !shouldGenerateOtp && (
                  <Box sx={{ marginTop: 2 }}>
                    {/* <input
                      type="text"
                      placeholder="Enter OTP"
                      name="otp"
                      className={styles.mobileFormInput}
                      required={pageOneData?.page?.isLeadOtpActive}
                      onChange={handleSetOtp}
                      value={otp}
                    /> */}

                    <OtpInput
                      value={otp}
                      onChange={(value: any) => {
                        if (isOnlyNumberAllowed(value) || value === "") {
                          setOtp(value);
                        }
                      }}
                      numInputs={6}
                      shouldAutoFocus={true}
                      // renderSeparator={<span>{" - "}</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{
                            height: isSmallScreen ? "30px" : "40px",
                            width: isSmallScreen ? "30px" : "40px",
                            marginRight: isSmallScreen ? "5px" : "10px",
                            textAlign:'center',
                            fontSize :isSmallScreen? '14px' : '16px' ,
                          }}
                          inputMode="numeric"
                        />
                      )}
                    />
                  </Box>
                )}

                {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}

                {counter !== 0 ? (
                  <div
                    style={{
                      marginTop: "30px",
                      textAlign: "center",
                    }}
                  >
                    RESEND OTP in <span>00:{counter} sec</span>
                  </div>
                ) : counter === 0 ? (
                  <button
                    onClick={resendOtp}
                    className={styles.otpResendButton}
                  >
                    Resend OTP
                  </button>
                ) : null}

                <button type="submit" className={styles.mobileSubmitBtn}>
                  {loader?.popUpEnquirySubmiting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </form>
            </div>
          </Box>
        </Modal>

        <div className={styles.callWithEnqueryForm}>
          {(location?.pathname === "/ayodhya" ||
            location?.pathname?.split("/")[2] === "Isle-Of-Bliss") && (
            <button
              className={styles.callNowBtn}
              onClick={handlePhoneNumberClick}
            >
              call now
            </button>
          )}

          <button
            className={styles.enQueryBtn}
            onClick={() => {
              if (props.setAyodhya) {
                props.setAyodhya(true);
              } else {
                setOpenMobileModal(true);
              }
            }}
          >
            Enquire NOW
          </button>
        </div>
      </div>
    </>
  );
};

export default EnqueryForm;
