import React, { useState, useEffect } from "react";
import styles from "./WebProfileHeader.module.css";
import MediaAssets from "assets";
import { Typography } from "@mui/material";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface WebHeaderInterface {
  contentHeading?: string;
  borderStyle?: boolean;
  backgroundColor?: string;
  middleDetails?: boolean;
  inputDivBackgroundColor?: string;
  inputDivBorder?: string;
  inputBorder?: string;
  inputBoxShadow?: string;
  inputOutline?: string;
  inputBackGroundColor?: string;
  inputImg?: string;
  contentColor?: string;
  stickyBackgroundColor?: string;
  websiteLogo?: string;
  inputPlaceHolderColor?: string;
  loginBackgroundColor?: string;
  loginContentColor?: string;
  containsUtm?:boolean;
}

const actionDispatch = (dispatch: any) => {
  return {
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
  };
};

const WebProfileHeader = (props: WebHeaderInterface) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);

  let headerUrlDatas = [
    {
      id: 1,
      url_name: "Home",
      url_route: token ? "/home" : "/",
    },
    {
      id: 2,
      url_name: "Portfolio",
      url_route: "/portfolio",
    },
    // {
    //   id: 3,
    //   url_name: "Invest",
    //   url_route: "/investments",
    // },
    {
      id: 4,
      url_name: "Life At HoABL",
      url_route: "/life-at-hoabl",
    },
    {
      id: 5,
      url_name: "Media & PR",
      url_route: "/media-pr",
    },
    {
      id: 6,
      url_name: "Information Center",
      url_route: "/blog",
    },
    {
      id: 7,
      url_name: "About Us",
      url_route: "/profile/about-us",
    },
    // {
    //   id: 8,
    //   url_name: "Scan & QR",
    //   url_route: "/rera-codes"
    // }
  ];

  if (!token) {
    headerUrlDatas[1] = {
      id: 2,
      url_name: "",
      url_route: "",
    };
  } else {
    headerUrlDatas[1] = {
      id: 2,
      url_name: "Portfolio",
      url_route: "/portfolio",
    };
  }

  const { getProfileDetails } = actionDispatch(useDispatch());

  const { profileDetails, isEditProfileDialogOpen } = useSelector(
    (state: any) => ({
      profileDetails: state?.profile?.profileDetails,
      isEditProfileDialogOpen: state?.editProfile?.isEditProfileDialogOpen,
    })
  );

  const [profilePicture, setProfilePicture] = useState("");

  const getProfilePicture = () => {
    if (profilePicture === "" || profilePicture === null) {
      if (
        profileDetails?.firstName !== "" &&
        profileDetails?.firstName !== null &&
        profileDetails?.lastName !== "" &&
        profileDetails?.lastName !== null
      ) {
        return `${profileDetails?.firstName
          ?.toUpperCase()
          ?.charAt(0)}${profileDetails?.lastName?.toUpperCase()?.charAt(0)}`;
      } else {
        if (
          profileDetails?.firstName !== null &&
          profileDetails?.firstName.length > 0
        ) {
          return `${profileDetails?.firstName
            ?.toUpperCase()
            ?.charAt(0)}${profileDetails?.firstName?.toLowerCase()?.charAt(1)}`;
        }
      }
    }
  };

  const displayFullName = () => {
    let fullName = "";
    if (profileDetails.firstName !== null && profileDetails.lastName !== null) {
      fullName = `${profileDetails.firstName} ${profileDetails.lastName}`;
    } else {
      fullName = `${profileDetails.firstName}`;
    }
    return fullName;
  };

  useEffect(() => {
    setProfilePicture(profileDetails?.profilePictureUrl);
    getProfilePicture();
  }, [
    profileDetails?.profilePictureUrl,
    profilePicture,
    isEditProfileDialogOpen,
  ]);

  useEffect(() => {
    if (location.pathname === "/home") {
      getProfileDetails();
    }
  }, [location]);

  const navigateHeaderController = (headerUrl: string) => {
    navigate(headerUrl);
  };

  const [stickyHeader, setStickyHeader] = useState<boolean | number>(false);

  useEffect(() => {
    const handleScroll = () => {
      setStickyHeader(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const homeIconHandler = () => {
    if (token) {
      navigate(RouteConfigurations.home);
    } else {
      navigate(RouteConfigurations.landing);
    }
  };

  return (
    <div
      style={{
        background: stickyHeader
          ? `${props.stickyBackgroundColor}`
          : `${props.backgroundColor}`,
          display: props?.containsUtm ? 'none' : ''
      }}
      className={
        stickyHeader
          ? styles.web_profile_sticky_container
          : styles.web_profile_container
      }
    >
      <div className={styles.web_profile_component}>
        <div
          className={styles.left_web_profile_component}
          onClick={() => homeIconHandler()}
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <img
            src={props.websiteLogo}
            //  style={{ width: "150px" }}
          />
        </div>
        {props.middleDetails && (
          <div
            style={{
              width: "100%",
              marginTop: "-5px",
              display: "flex",
              alignItems: "center",
              padding: token ? "0px 30px" : "0px 70px",
            }}
            className={styles.header}
          >
            <div
              style={{
                width: token ? "100%" : "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {headerUrlDatas.map((headerUrlData: any, index: any) => {
                if (headerUrlData.url_route !== "")
                  return (
                    <div
                      key={headerUrlData?.id}
                      className={`${styles.menu_item_style} ${styles.menu_item_container}`}
                      style={{
                        fontFamily: "Futura Md BT",
                        fontStyle: "normal",
                        fontWeight: 400,
                        // fontSize: "18px",
                        padding: token ? "0px 10px" : "0px 15px",
                        color:
                          location?.pathname === headerUrlData?.url_route
                            ? props.contentColor
                            : "#727272",
                        // ...(index !== headerUrlDatas.length - 1 && { borderRight: '0.5px solid gray' }),
                      }}
                      onClick={() =>
                        navigateHeaderController(headerUrlData?.url_route)
                      }
                    >
                      {headerUrlData?.url_name}
                    </div>
                  );
              })}
            </div>
          </div>
        )}

        {token ? (
          <>
            {" "}
            <div className={styles.right_web_profile_component}>
              <div className={styles.message_div_style}>
                <img src={MediaAssets.web_msg_icon} />
              </div>
              <div className={styles.notification_div_style}>
                <img src={MediaAssets.web_notification_icon} />
              </div>
              <div className={styles.profile_div_style}>
                <div className={styles.profile_pic_div_style}>
                  {profilePicture ? (
                    <img
                      src={profilePicture}
                      className={styles.profile_pic_img_style}
                    />
                  ) : (
                    <h3 className={styles.profile_name_h3_style}>
                      {getProfilePicture()}
                    </h3>
                  )}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <h5 className={styles.profile_name_style}>
                    {displayFullName()}
                  </h5>
                  <h6
                    className={styles.profile_view_details_style}
                    onClick={() => navigate(RouteConfigurations.profile)}
                  >
                    View Detail
                  </h6>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            {" "}
            {/* <button
              style={{
                background: `${props.loginBackgroundColor}`,
                color: `${props.loginContentColor}`,
              }}
              className={styles.loginSignup}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login/Signup
            </button> */}
          </>
        )}
      </div>
      {props.borderStyle && (
        <div className={styles.web_profile_border_style}></div>
      )}
      <div className={styles.icon_style} onClick={() => navigate(-1)}>
        {props.contentHeading && <img src={MediaAssets.profile_arrow_icon} />}
        <Typography className={styles.profile_heading}>
          {props.contentHeading}
        </Typography>
      </div>
    </div>
  );
};

export default WebProfileHeader;
