import apisauce from "apisauce";
import { Constants } from "../constants/Constants";
import { HttpResponseTypeEnum } from "../enumerations/HttpResponseTypeEnum";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../utils/LocalStorageUtils";
import axios from "axios";

const create = (baseURL = Constants.PRODUCTION_BASE_URL) => {
  const adminAPI = "hoabl-admin";
  const customerAPI = "hoabl-customer";
  const notificationsAPI = "hoabl-notifications";

  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      Authorization: getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN),
      apptype: "app",
    },
    // timeout: 10000,
  });

  const apiV2 = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "version": 'v2'
    },
    // timeout: 10000,
  });
  api.addMonitor((response) => {
    if (response.status === HttpResponseTypeEnum.Unauthorized) {
      localStorage.clear();
    }
    if (response.status === HttpResponseTypeEnum.Forbidden) {
      setItemToLocalStorage("isSessionExpired", "true");
    }
  });

  const setAuthorization = () => {
    const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);
    api.headers["Authorization"] = token;
  };

  // AUTHENTICATION APIs
  const generateOtp = async (data: any) =>
    api.post(`${customerAPI}/generate_otp`, data);
  const verifyOtp = async (data: any) =>
    api.put(`${customerAPI}/verify_otp`, data);
  const setNewUserName = (data: any) => {
    setAuthorization();
    return api.put(`${customerAPI}/set_name`, data);
  };
  const troubleSigninCase = (data: any) =>
    api.post(`${customerAPI}/case`, data);
  // HOMEPAGE APIs
  const addReferral = (data: any) =>
    api.post(`${customerAPI}/addReferral`, data);

  // const getPageDetails = (pageType: string) => {
  //   setAuthorization();
  //   return api.get(`${adminAPI}/get-page?pageType=${pageType}`);
  // };

  const getInsightDetails = () =>
    api.get(`${adminAPI}/insights?byPrority=true`);

  // const getTestimonials = (data: any) => {
  //   let params = ``;
  //   for (const key in data) {
  //     let value = data[key];
  //     if (value) {
  //       params += `${key}=${value}&`;
  //     }
  //   }
  //   return api.get(`${adminAPI}/testimonials`);
  // };

  const getUpdates = (data: any) => {
    // let params = ``;
    // for (const key in data) {
    //   let value = data[key];
    //   if (value) {
    //     params += `${key}=${value}&`;
    //   }
    // }
    return api.get(`${adminAPI}/marketing-updates`);
  };

  const getPortfolioPreview = () => {
    setAuthorization();
    return api.get(`${customerAPI}/portfolio-preview`);
  };

  const getPortfolioSummary = () => {
    return api.get(`${customerAPI}/investment-summary`);
  };

  const getPortfolioDetails = (data: any) => {
    return api.get(
      `${customerAPI}/investment-details?investmentId=${data.investmentId}&projectId=${data.projectId}`
    );
  };

  const getWatchListData = () => {
    return api.get(`${customerAPI}/watchlist`);
  };

  const getNotifications = () => {
    setAuthorization();
    return api.get(`${notificationsAPI}/unread-notifications`);
  };
  const updateNotificationStatus = (data: any) =>
    api.post(`${notificationsAPI}/update-read-status?id=${data.id}`);

  // const getProjectContent = (data: any) => {
  //   let params = ``;
  //   for (const key in data) {
  //     let value = data[key];
  //     if (value) {
  //       params += `${key}=${value}&`;
  //     }
  //   }
  //   return api.get(`${adminAPI}/project-contents?${params}`);
  // };

  // const getProjectContentById = (id: any) => {
  //   return api.get(`${adminAPI}/project-contents/${id}`);
  // };

  const getCategories = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`${adminAPI}/categories?${params}`);
  };

  const getFaqs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`${adminAPI}/faqs?${params}`);
  };

  const getProfileDetails = () => {
    setAuthorization();
    return api.get(`${customerAPI}/profile/get`);
  };

  const editProfileUserSettings = (data: any) => {
    return api.put(`${customerAPI}/profile/user-settings`, data);
  };

  // const getMediaGalleries = (projectContentId: number) =>
  //   api.get(`${adminAPI}/project-contents/${projectContentId}/media-galleries`);

  const logout = () => api.post(`${customerAPI}/profile/log-out-current`);
  const logoutFromAllDevices = () =>
    api.post(`${customerAPI}/profile/log-out-all`);

  const getUserActionItem = () => api.get(`${customerAPI}/user/action-items`);

  const getMyAccountDetails = () => api.get(`${customerAPI}/profile/account`);

  const shareFeedback = (data: any) =>
    api.post(`${customerAPI}/profile/feedback`, data);

  const downloadDoc = (data: any) => {
    return api.get(`${customerAPI}/doc-download?path=${data}`);
  };

  const docUpload = (data: any) => {
    // api.headers["Content-type"] = 'multipart/form-data;'
    // return api.post(`${customerAPI}/doc-upload`, data);
    const docUploadDetails = axios.post(
      `${Constants.PRODUCTION_BASE_URL}${customerAPI}/doc-upload`,
      data,
      {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN),
          apptype: "app",
        },
      }
    );
    return docUploadDetails;
  };

  //chat
  const getChat = () => api.get(`${customerAPI}/chats`);
  const getChatData = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(
      `${customerAPI}/chat/get?topicId=${data?.topicId}&isInvested=${data?.isInvested}`
    );
  };
  const chatPostData = (data: any) =>
    api.post(`${customerAPI}/chat/message`, data);
  const chatInitiate = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.put(
      `${customerAPI}/chat/initiate?topicId=${data?.topicId}&isInvested=${data?.isInvested}`
    );
  };

  //search
  const getAdminSearch = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }

    return api.get(`${adminAPI}/search?${params}`);
  };

  const getCustomerSearch = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }

    return api.get(`${customerAPI}/doc-filter?${params}`);
  };
  const getPrivacyPolicy = (pageType: any) => {
    return api.get(`${adminAPI}/get-page?pageType=${5005}`);
  };

  // Edit Profile APIs
  const editProfile = (data: any) => {
    return api.put(`/hoabl-customer/profile`, data);
  };
  const getCountries = () => {
    return api.get(`${customerAPI}/profile/countries`);
  };
  const getStates = (countryCode: any) => {
    return api.get(`${customerAPI}/profile/states/${countryCode}`);
  };
  const getCities = (stateIsoCode: any, countryIsoCode: any) => {
    return api.get(
      `${customerAPI}/profile/cities?stateIsoCode=${stateIsoCode}&countryIsoCode=${countryIsoCode}`
    );
  };
  const updateProfilePicture = (data: any) => {
    return api.put(`${customerAPI}/profile/picture`, data);
  };
  const deleteProfilePicture = (key: any) => {
    return api.delete(`${customerAPI}/profile/picture/${key}`);
  };
  // const landSkuList = (id: any) => {
  //   return api.get(`${customerAPI}/launch-inventory-buckets/${id}`);
  // };

  const videoCallApi = (data: any) => {
    return api.post(`${customerAPI}/case`, data);
  };

  const addInventoryApi = (data: any) => {
    return api.post(`${customerAPI}/apply-now`, data);
  };

  //portfolio
  const getBookingJourney = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`${customerAPI}/booking-journey?${params}`);
  };

  const getProjectFaqs = (id: any) => {
    return api.get(`${adminAPI}/faqs/project-contents/${id}`);
  };
  // Investments APIs
  // const getProjectDetails = (id: number) => {
  //   return api.get(`${adminAPI}/project-contents/${id}`);
  // };
  // const getAllProjects = () => {
  //   return api.get(`${adminAPI}/project-contents`);
  // };

  const addToWatchlist = (data: any) => {
    return api.post(`${customerAPI}/watchlist`, data);
  };

  const removeFromWatchlist = (id: any) => {
    return api.delete(`${customerAPI}/watchlist/${id}`);
  };

  return {
    getPortfolioDetails,
    getWatchListData,
    getPortfolioSummary,
    getUserActionItem,
    verifyOtp,
    getInsightDetails,
    generateOtp,
    // getPageDetails,
    setNewUserName,
    addReferral,
    troubleSigninCase,
    //getTestimonials,
    getUpdates,
    getPortfolioPreview,
    getNotifications,
    updateNotificationStatus,
    // getProjectContent,
    getCategories,
    getFaqs,
    getProfileDetails,
    getMyAccountDetails,
    editProfileUserSettings,
    // getMediaGalleries,
    logout,
    logoutFromAllDevices,
    shareFeedback,
    downloadDoc,
    docUpload,
    getChat,
    getChatData,
    chatInitiate,
    chatPostData,
    getAdminSearch,
    getCustomerSearch,
    editProfile,
    getCountries,
    getStates,
    getCities,
    updateProfilePicture,
    deleteProfilePicture,
    getPrivacyPolicy,
    // getProjectContentById,
    getBookingJourney,
    // getProjectDetails,
    // getAllProjects,
    getProjectFaqs,
    // landSkuList,
    videoCallApi,
    addInventoryApi,
    addToWatchlist,
    removeFromWatchlist,
  };
};

const preCreate = (baseURL = Constants.PRODUCTION_BASE_URL) => {
  const adminAPI = "hoabl-admin";
  const customerAPI = "hoabl-customer";
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      // apptype: "app",
      "version": 'v2'
    },
    // timeout: 10000,
  });


  const getPreLoginPageDetails = (pageType: string) => {
    return api.get(`${adminAPI}/pre-login/get-page?pageType=${pageType}`);
  };

  // comming soon product

  const fetchCommingSoonProjects = (pageType: string) => {
    return api.get(`${adminAPI}/comming-sson`);
  };
  // page seo

  const fetchPageSeoData = (pageName: string) => {
    return api.get(`${adminAPI}/get-page-seo-name?pageName=${pageName}`);
  };
  const getPageDetails = (pageType: string) => {
    return api.get(`${adminAPI}/pre-login/get-page?pageType=${pageType}`);
  };

  const getAllProjects = () => {
    return api.get(`${adminAPI}/pre-login/project-contents`);
  };

  const getMediaGalleries = (projectContentId: number) => {
    return api.get(
      `${adminAPI}/pre-login/project-contents/${projectContentId}/media-galleries`
    );
  };

  const getProjectDetails = (id: number) => {
    // let baseURL="http://192.168.1.36:8002/"
    // const apiCustomer = apisauce.create({
    //   baseURL,
    //   headers: {
    //     "Cache-Control": "no-cache",
    //     "Content-Type": "application/json",
    //     apptype: "app",
    //   },
    //   timeout: 10000,
    // });
    return api.get(`${adminAPI}/pre-login/project-contents/${id}`);
  };

  const landSkuList = (id: any) => {
    let baseURL = "https://api-partners.hoabl.in:8002/";
    const apiCustomer = apisauce.create({
      baseURL,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        apptype: "app",
      },
      timeout: 10000,
    });

    return apiCustomer.get(
      `${customerAPI}/pre-login/launch-inventory-buckets/${id}`
    );
  };

  const getProjectContent = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`${adminAPI}/pre-login/project-contents?${params}`);
  };

  const getProjectContentById = (id: any) => {
    return api.get(`${adminAPI}/pre-login/project-contents/${id}`);
  };

  const getWatchListData = () => {
    return api.get(`${customerAPI}/pre-login/watchlist`);
  };

  const getMediaAndPrs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }

    return axios.get(
      `https://cms-api.hoabl.in/${adminAPI}/media-and-prs?${params}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          apptype: "home",
        },
      }
    );
  };

  return {
    getPreLoginPageDetails,
    fetchCommingSoonProjects,
    getPageDetails,
    fetchPageSeoData,
    getAllProjects,
    getMediaGalleries,
    getProjectDetails,
    landSkuList,
    getProjectContent,
    getProjectContentById,
    getWatchListData,
    getMediaAndPrs,
  };
};
export default {
  create,
  preCreate,
};
