import { Fragment, lazy } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RouteConfigurations } from "./RouteConfigurations";
import { Constants } from "../constants/Constants";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";
// import ProtectedRoute from "./ProtectedRoutes";
// import Login from "pages/authentication/login/Login";
import Promises from "pages/promises/Promises";
// import Profile from "pages/profile/Profile";
// import Portfolio from "pages/portfolio/Portfolio";

const Investments = lazy(() => import("pages/investments/Investments"));
const AboutUs = lazy(() => import("pages/profile/about-us/AboutUs"));
const InvestmentOpportunityDoc = lazy(() => import("pages/investment-opportunity-doc/InvestmentOpportunityDoc"));
const InvestmentLocationMap = lazy(() => import("pages/investment-location-map/InvestmentLocationMap"));
const InvestMentDetailPage = lazy(() => import("pages/investments/investment-detail-page/InvestMentDetailPage"));
const NewLaunch = lazy(() => import("pages/investments/new-launch/NewLaunch"));
const Landing = lazy(() => import("pages/landing/Landing"));
const Blog = lazy(() => import("pages/blog/Blog"));
const SinglePage = lazy(() => import("pages/blog/single-blog-page/SinglePage"));
const Menu = lazy(() => import("pages/menu/Menu"));
const AllInvestments = lazy(
  () => import("pages/investments/all-investments/AllInvestments")
);
const FastSelling = lazy(
  () => import("pages/investments/fast-selling/FastSelling")
);
const SoldProjects = lazy(
  () => import("pages/investments/sold-projects/SoldProjects")
);
const InvestmentProjectDetailPage = lazy(() =>import("pages/investments/investment-project-detail-page/InvestmentProjectDetailPage"));
const InventoryBucket = lazy(() => import("pages/investments/inventory-buckets/InventoryBucket"));
const Accordion = lazy(() => import("components/custom-aacordion/Accordion"));
const LifeAtHoabl = lazy(() => import("pages/life-at-hoabl/LifeAtHoabl"));
const MediaPr = lazy(() => import("pages/media-pr/MediaPr"));
const MediaPrDetails = lazy(
  () => import("pages/media-pr/media-pr-details/MediaPrDetails")
);
const Submited = lazy(() => import("pages/ayodhya/Submited"));
const AyodhyaLatestNews = lazy(
  () => import("pages/ayodhya-latest-news/AyodhyaLatestNews")
);
const Goa = lazy(() => import("pages/goa/Goa"));
const AyodhyaPartnerSummit = lazy(
  () => import("pages/ayodhya-partnersummit/AyodhyaPartnerSummit")
);
const Thankyou = lazy(() => import("pages/ayodhya-partnersummit/Thankyou"));
const ThankYou = lazy(() => import("pages/landing/enquery-form/ThankYou"));
const TAC = lazy(() => import("pages/Terms-and-condition/TAC"));
const ScanQR = lazy(() => import("pages/Scan&QR/ScanQR"));
const AccontDeletion = lazy(
  () => import("pages/account-deletion/AccontDeletion")
);

// import AllTestimonials from "pages/all-testimonials/AllTestimonials";
// import Insights from "pages/insights/Insights";
// import Updates from "pages/updates/Updates";
// import HelpCenter from "pages/profile/help-center/HelpCenter";
// import FaqSection from "pages/profile/faq-section/FaqSection";
// import SecurityAndSetting from "pages/profile/security-settings/SecuritySettings";
// import PortfolioDetails from "pages/portfolio/PortfolioDetails";
// import Chat from "pages/chat/Chat";
// import MyAccount from "pages/profile/my-account/MyAccount";
// import AllDocuments from "pages/profile/my-account/AllDocuments";
// import ProjectTimeline from "pages/portfolio/project-timeline/ProjectTimeline";
// import MediaGallery from "../pages/media-gallery/MediaGallery";
// import BookingJourney from "../pages/portfolio/booking-journey/BookingJourney";
// import InvestmentDetails from "pages/investment-details/InvestmentDetails";

// import AllPaymentDetails from "pages/profile/my-account/AllPayments";
// import Search from "pages/search/Search";
// import LandSku from "pages/investments/land-sku-list/LandSku";
// import InvestmentSummaryMap from "pages/portfolio/portfolio-sub-components/InvestmentSummaryMap";
// import MyService from "pages/my-services/MyService";
// import UserEditProfile from "pages/profile/user-edit-profile/UserEditProfile";
// import UserDeatils from "pages/profile/user-details/UserDeatils";
// import ProfileDetails from "pages/profile/profile-details/ProfileDetails";

// import RedirectToPaymentGateWay from "pages/investments/inventory-buckets/RedirectToPaymentGateWay";
// import Notifications from "pages/notifications/Notifications";

// import Projects from "pages/landing/post-landing-portfolio/Projects";
// import Ayodhya from "pages/ayodhya/Ayodhya";

const AppRoutes = () => {
  const isLoggedIn = useSelector((state: any) => state?.verifyOtp.isLoggedIn);

  const isLoggedInAfterNewUsername = useSelector(
    (state: any) => state?.newUserNameInfo.isLoggedIn
  );

  const isOtpVerified = useSelector(
    (state: any) => state?.verifyOtp.isOtpVerified
  );

  // const blogsCon = useSelector((state: any) => {
  //   return state?.pageManagement?.pageData?.pageManagementOrLatestUpdates;
  // });

  let routes = (
    // public routes
    <Fragment>
      {/* <Route path={RouteConfigurations.login} element={<Login />} />*/}
      <Route path={RouteConfigurations.landing} element={<Landing />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="/blog"
        element={
          <>
            <Blog />
          </>
        }
      />
      <Route
        path={RouteConfigurations.preLoginPromise}
        element={<Promises />}
      />
      <Route path="/custom-acco" element={<Accordion />} />
      <Route
        path="/blog/:title"
        element={
          <>
            <SinglePage />
          </>
        }
      />
      <Route path={RouteConfigurations.lifeAtHoabl} element={<LifeAtHoabl />} />
      <Route
        path="/menu"
        element={
          <>
            <Menu />
          </>
        }
      />
      <Route path={RouteConfigurations.investments} element={<Investments />} />

      <Route path={RouteConfigurations.newLaunch} element={<NewLaunch />} />
      {/* <Route path={RouteConfigurations.ayodhya} element={<Ayodhya />} /> */}
      <Route
        path={RouteConfigurations.ayodhyaLatestNews}
        element={<AyodhyaLatestNews />}
      />
      <Route path={RouteConfigurations.submited} element={<Submited />} />
      <Route path={RouteConfigurations.goa} element={<Goa />} />

      <Route
        path={RouteConfigurations.allInvestments}
        element={<AllInvestments />}
      />
      <Route path={RouteConfigurations.fastSelling} element={<FastSelling />} />
      <Route
        path={RouteConfigurations.soldProject}
        element={<SoldProjects />}
      />
      <Route
        path={RouteConfigurations.investMentDetail}
        element={<InvestMentDetailPage />}
      />
      <Route
        path={RouteConfigurations.iventoryBuckets}
        element={<InventoryBucket />}
      />
      <Route
        path={RouteConfigurations.investProjectDetail}
        element={<InvestmentProjectDetailPage />}
      />
      <Route
        path={RouteConfigurations.viewOnMap}
        element={<InvestmentLocationMap />}
      />
      <Route
        path={RouteConfigurations.whyToInvest}
        element={<InvestmentOpportunityDoc />}
      />
      {/* <Route path={RouteConfigurations.login} element={<Login />} /> */}
      <Route path={RouteConfigurations.aboutUs} element={<AboutUs />} />
      <Route path={RouteConfigurations.mediaPr} element={<MediaPr />} />
      <Route
        path={RouteConfigurations.mediaPrDetail}
        element={<MediaPrDetails />}
      />
      <Route
        path={RouteConfigurations.ayodhyaPartnerSubmmit}
        element={<AyodhyaPartnerSummit />}
      />
      <Route
        path={RouteConfigurations.ayodhyaPartnerSubmmitThankyou}
        element={<Thankyou />}
      />
      <Route path={RouteConfigurations.thankYou} element={<ThankYou />} />
      <Route path={RouteConfigurations.TAC} element={<TAC />} />
      <Route path={RouteConfigurations.scanQR} element={<ScanQR />} />
      <Route
        path={RouteConfigurations.accountDeletion}
        element={<AccontDeletion />}
      />
    </Fragment>
  );

  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);

  // Routes after login
  // if (
  //   (!isOtpVerified && token && isLoggedIn === true) ||
  //   (!isOtpVerified &&
  //     token &&
  //     isLoggedIn === false &&
  //     isLoggedInAfterNewUsername === true)
  // ) {
  //   routes = (
  //     <Route element={<ProtectedRoute />}>
  //       <Route path={RouteConfigurations.home} element={<Landing />} />
  //       <Route path="/" element={<Navigate to="/home" />} />
  //       <Route path={"*"} element={<Landing />} />
  //       {/* <Route path={RouteConfigurations.ayodhya} element={<Ayodhya />} /> */}
  //       <Route
  //         path={RouteConfigurations.ayodhyaLatestNews}
  //         element={<AyodhyaLatestNews />}
  //       />
  //       <Route path={RouteConfigurations.submited} element={<Submited />} />
  //       <Route
  //         path="/blog"
  //         element={
  //           <>
  //             <Blog />
  //           </>
  //         }
  //       />
  //       <Route
  //         path="/projects"
  //         element={
  //           <>
  //             <Projects />
  //           </>
  //         }
  //       />
  //       <Route
  //         path="/blog/:title"
  //         element={
  //           <>
  //             <SinglePage />
  //           </>
  //         }
  //       />
  //       <Route
  //         path={RouteConfigurations.lifeAtHoabl}
  //         element={<LifeAtHoabl />}
  //       />
  //       <Route path={RouteConfigurations.mediaPr} element={<MediaPr />} />
  //       <Route
  //         path={RouteConfigurations.mediaPrDetail}
  //         element={<MediaPrDetails />}
  //       />
  //       <Route
  //         path={RouteConfigurations.investments}
  //         element={<Investments />}
  //       />
  //       <Route
  //         path={RouteConfigurations.whyToInvest}
  //         element={<InvestmentOpportunityDoc />}
  //       />
  //       <Route
  //         path={RouteConfigurations.investmentDetails}
  //         element={<InvestmentDetails />}
  //       />
  //       <Route
  //         path={RouteConfigurations.viewOnMap}
  //         element={<InvestmentLocationMap />}
  //       />
  //       <Route
  //         path={RouteConfigurations.iventoryBuckets}
  //         element={<InventoryBucket />}
  //       />
  //       <Route
  //         path={RouteConfigurations.redirectToPaymentGateway}
  //         element={<RedirectToPaymentGateWay />}
  //       />

  //       <Route path="/notification" element={<Notifications />} />
  //       <Route
  //         path={RouteConfigurations.investmentOpportunityDoc}
  //         element={<InvestmentOpportunityDoc />}
  //       />
  //       <Route path={RouteConfigurations.newLaunch} element={<NewLaunch />} />
  //       <Route
  //         path={RouteConfigurations.allInvestments}
  //         element={<AllInvestments />}
  //       />
  //       <Route
  //         path={RouteConfigurations.fastSelling}
  //         element={<FastSelling />}
  //       />
  //       <Route
  //         path={RouteConfigurations.soldProject}
  //         element={<SoldProjects />}
  //       />
  //       <Route
  //         path={RouteConfigurations.investmentLocation}
  //         element={<InvestmentLocationMap />}
  //       />
  //       <Route
  //         path={RouteConfigurations.investmentSummaryMap}
  //         element={<InvestmentSummaryMap />}
  //       />
  //       <Route
  //         path={RouteConfigurations.investMentDetail}
  //         element={<InvestMentDetailPage />}
  //       />
  //       <Route path={RouteConfigurations.insights} element={<Insights />} />
  //       <Route path={RouteConfigurations.portfolio} element={<Portfolio />} />
  //       <Route
  //         path={RouteConfigurations.portfolioDetails}
  //         element={<PortfolioDetails />}
  //       />
  //       <Route path={RouteConfigurations.promise} element={<Promises />} />
  //       <Route path={RouteConfigurations.myService} element={<MyService />} />
  //       <Route path={RouteConfigurations.profile} element={<Profile />} />
  //       <Route
  //         path={RouteConfigurations.editProfile}
  //         element={<UserEditProfile />}
  //       />
  //       <Route
  //         path={RouteConfigurations.userDetails}
  //         element={<UserDeatils />}
  //       />
  //       <Route
  //         path={RouteConfigurations.profileDetails}
  //         element={<ProfileDetails />}
  //       />
  //       <Route
  //         path={RouteConfigurations.projectTimeline}
  //         element={<ProjectTimeline />}
  //       />
  //       <Route
  //         path={RouteConfigurations.testimonials}
  //         element={<AllTestimonials />}
  //       />
  //       <Route path={RouteConfigurations.updates} element={<Updates />} />

  //       <Route path={RouteConfigurations.helpCenter} element={<HelpCenter />} />
  //       <Route path={RouteConfigurations.aboutUs} element={<AboutUs />} />

  //       <Route path={RouteConfigurations.faqs} element={<FaqSection />} />
  //       <Route
  //         path={RouteConfigurations.securityAndSettings}
  //         element={<SecurityAndSetting />}
  //       />
  //       <Route path={RouteConfigurations.chat} element={<Chat />} />
  //       <Route
  //         path={RouteConfigurations.securityAndSettings}
  //         element={<SecurityAndSetting />}
  //       />
  //       <Route path={RouteConfigurations.myAccount} element={<MyAccount />} />
  //       <Route
  //         path={RouteConfigurations.documentDetails}
  //         element={<AllDocuments />}
  //       />
  //       <Route
  //         path={RouteConfigurations.paymentHistory}
  //         element={<AllPaymentDetails />}
  //       />
  //       <Route
  //         path={RouteConfigurations.documentDetails}
  //         element={<AllDocuments />}
  //       />
  //       <Route
  //         path={RouteConfigurations.paymentHistory}
  //         element={<AllPaymentDetails />}
  //       />
  //       <Route path={RouteConfigurations.search} element={<Search />} />
  //       <Route
  //         path={RouteConfigurations.mediaGallery}
  //         element={<MediaGallery />}
  //       />
  //       <Route
  //         path={RouteConfigurations.bookingJourney}
  //         element={<BookingJourney />}
  //       />

  //       <Route path={RouteConfigurations.landSkuList} element={<LandSku />} />
  //       <Route
  //         path={RouteConfigurations.investProjectDetail}
  //         element={<InvestmentProjectDetailPage />}
  //       />
  //       <Route
  //         path={RouteConfigurations.ayodhyaPartnerSubmmit}
  //         element={<AyodhyaPartnerSummit />}
  //       />
  //       <Route
  //         path={RouteConfigurations.ayodhyaPartnerSubmmitThankyou}
  //         element={<Thankyou />}
  //       />
  //       <Route path={RouteConfigurations.thankYou} element={<ThankYou />} />
  //       <Route path={RouteConfigurations.TAC} element={<TAC />} />
  //     </Route>
  //   );
  // }
  return (
    <Routes>
      <Route path={RouteConfigurations.landing} element={<Landing />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="/blog"
        element={
          <>
            <Blog />
          </>
        }
      />
      <Route
        path={RouteConfigurations.preLoginPromise}
        element={<Promises />}
      />
      <Route path="/custom-acco" element={<Accordion />} />
      <Route
        path="/blog/:title"
        element={
          <>
            <SinglePage />
          </>
        }
      />
      <Route path={RouteConfigurations.lifeAtHoabl} element={<LifeAtHoabl />} />
      <Route
        path="/menu"
        element={
          <>
            <Menu />
          </>
        }
      />
      <Route path={RouteConfigurations.investments} element={<Investments />} />

      <Route path={RouteConfigurations.newLaunch} element={<NewLaunch />} />
      {/* <Route path={RouteConfigurations.ayodhya} element={<Ayodhya />} /> */}
      <Route
        path={RouteConfigurations.ayodhyaLatestNews}
        element={<AyodhyaLatestNews />}
      />
      <Route path={RouteConfigurations.submited} element={<Submited />} />
      <Route path={RouteConfigurations.goa} element={<Goa />} />

      <Route
        path={RouteConfigurations.allInvestments}
        element={<AllInvestments />}
      />
      <Route path={RouteConfigurations.fastSelling} element={<FastSelling />} />
      <Route
        path={RouteConfigurations.soldProject}
        element={<SoldProjects />}
      />
      <Route
        path={RouteConfigurations.investMentDetail}
        element={<InvestMentDetailPage />}
      />
      <Route
        path={RouteConfigurations.iventoryBuckets}
        element={<InventoryBucket />}
      />
      <Route
        path={RouteConfigurations.investProjectDetail}
        element={<InvestmentProjectDetailPage />}
      />
      <Route
        path={RouteConfigurations.viewOnMap}
        element={<InvestmentLocationMap />}
      />
      <Route
        path={RouteConfigurations.whyToInvest}
        element={<InvestmentOpportunityDoc />}
      />
      {/* <Route path={RouteConfigurations.login} element={<Login />} /> */}
      <Route path={RouteConfigurations.aboutUs} element={<AboutUs />} />
      <Route path={RouteConfigurations.mediaPr} element={<MediaPr />} />
      <Route
        path={RouteConfigurations.mediaPrDetail}
        element={<MediaPrDetails />}
      />
      <Route
        path={RouteConfigurations.ayodhyaPartnerSubmmit}
        element={<AyodhyaPartnerSummit />}
      />
      <Route
        path={RouteConfigurations.ayodhyaPartnerSubmmitThankyou}
        element={<Thankyou />}
      />
      <Route path={RouteConfigurations.thankYou} element={<ThankYou />} />
      <Route path={RouteConfigurations.TAC} element={<TAC />} />
      <Route path={RouteConfigurations.scanQR} element={<ScanQR />} />
      <Route
        path={RouteConfigurations.accountDeletion}
        element={<AccontDeletion />}
      />
    </Routes>
  );
};

export default AppRoutes;
