import EnqueryForm from "../enquery-form/EnqueryForm";
import styles from "./projects.module.css";
import packageInfo from "../../../../package.json";

interface MobileNumberInterface {
  mobilePhoneNumber?: any;
  selectedProject?: any;
  setAyodhya?: any;
  page?: any;
  bookNowBtnClicked?: any;
  setBookNowBtnClicked?: any;

  openEnquiryPopUpManualy?: boolean;
  setOpenEnquiryPopUpManualy?: any;
  catalogue?: any;
  displayInLeadForm?:any;
}

const Project = (props: MobileNumberInterface) => {
  // console.log("props of projects", props);

  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.topContent}>
          <h2 className={styles.heading}>INVEST WITH US</h2>
          <p className={styles.content}>
            With the power of digitization, land with Hoabl is free from the
            claws of risk, red tape, dicey, and lengthy processes. As a limited
            asset, land has stood the test of time, making it intergenerational
            and having unlimited growth potential. These attributes mean land
            can enhance a portfolio in three key ways:
            <div>1. Delivering long-term returns</div>
            <div>2. Improving diversification</div>
            <div>3. Providing liquidity</div>
            Combined, these three characteristics make land a clear complement
            to gold, stocks, and bonds and a welcome addition to broad-based
            portfolios.
          </p>
        </div> */}
        {/* <hr
          style={{
            width: "100%",
            backgroundColor: "#F3F4F6",
            height: "2px",
            border: "none",
          }}
          className={styles.hrTag}
        ></hr> */}
        <div
          style={{ width: "100%", height: "50px", padding: "0 8.5rem" }}
          className={styles.version}
        >
          <p
            style={{
              marginTop: "0.5rem ",
              fontSize: "16px",
              color: "rgb(149 151 155)",
              fontWeight: "400",
            }}
          >
            Version : {packageInfo.version}
          </p>
        </div>

        <EnqueryForm
          setAyodhya={props.setAyodhya}
          mobilePhoneNumber={props?.mobilePhoneNumber}
          selectedProject={props?.selectedProject}
          page={props?.page}
          bookNowBtnClicked={props.bookNowBtnClicked}
          setBookNowBtnClicked={props.setBookNowBtnClicked}
          openEnquiryPopUpManualy={props.openEnquiryPopUpManualy}
          setOpenEnquiryPopUpManualy={props.setOpenEnquiryPopUpManualy}
          catalogue={props.catalogue}
          displayInLeadForm ={props.displayInLeadForm}
        />
      </div>
    </>
  );
};

export default Project;
